import React from 'react';

import Hero from '../../components/Hero/Hero';
import PostListView from '../../components/PostListView';
import { BLOG_CATEGORY } from '../../config';
import usePncBlogLatestList from '../../hooks/data/usePncBlogLatestList';
import HERO_MOBILE from '../../images/pnc/m_main_hero.png';
import HERO_PC from '../../images/pnc/main_hero.png';

const Pnc: React.FC = () => (
  <div>
    <Hero
      subTitle="뱅크샐러드 컬쳐 블로그"
      title="우리는 결국 문화가, <br />세상을 바꾼다고 믿습니다"
      cover={HERO_PC}
      mCover={HERO_MOBILE}
    />
    <PostListView category={BLOG_CATEGORY.PNC} contentsSelector={usePncBlogLatestList} />
  </div>
);

export default Pnc;
